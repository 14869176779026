import React from 'react';
import { useTranslation } from 'react-i18next';

import SEO, { TYPES } from '../components/seo';
import { HomeLayout } from '../components/Layouts/layout';
import { MainSection } from '../components/Section/main.component';
import { BnbSection } from '../components/Section/bnb.component';
import { ActivitesSection } from '../components/Section/activities.component';
import { CastleSection } from '../components/Section/castle.component';
import { SpaceSection } from '../components/Section/space.component';
import { ArticleSection } from '../components/Section/articles.component';
import withI18next from '../i18n';

const Home = ({ location, pageContext }) => {
  const { t } = useTranslation();
  return (
    <HomeLayout lang={pageContext.langKey} location={location}>
      <SEO
        title={t('home.seo.title')}
        description={t('home.seo.description')}
        location={location}
        lang={pageContext.langKey}
        type={TYPES.home}
      />
      <MainSection />
      <BnbSection />
      <CastleSection />
      <SpaceSection />
      <ArticleSection lang={pageContext.langKey} />
      <ActivitesSection />
    </HomeLayout>
  );
};

export default withI18next(Home);
