import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GiCastle } from 'react-icons/all';

import { StyledSection } from '../styles';
import { TypicalSection } from '../TypicalSection/typical.component';
import { Routes } from '../routes';

export const CastleSection = () => {
  const data = useStaticQuery(graphql`
    query {
      first: file(relativePath: { eq: "chateau_de_morey.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <StyledSection charlie>
      <TypicalSection
        title="home.castle.title"
        image={data.first}
        description="home.castle.description"
        contents={['home.castle.p1', 'home.castle.p2']}
        buttonText="home.castle.cta"
        buttonPath={Routes.CASTLE}
        buttonIcon={GiCastle}
        imageAlt="home.castle.imageAlt"
      />
    </StyledSection>
  );
};
