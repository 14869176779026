import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { IoMdBed } from 'react-icons/all';

import { StyledSection } from '../styles';
import { TypicalSection } from '../TypicalSection/typical.component';
import { Routes } from '../routes';

export const BnbSection = () => {
  const data = useStaticQuery(graphql`
    query {
      first: file(
        relativePath: { eq: "2020/04/airbnb-chambres-dhotes-nancy.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <StyledSection beta>
      <TypicalSection
        title="home.bnb.title"
        image={data.first}
        description="home.bnb.description"
        contents={['home.bnb.p1', 'home.bnb.p2']}
        buttonText="home.bnb.cta"
        imageAlt="home.bnb.imageAlt"
        buttonPath={Routes.BNB}
        buttonIcon={IoMdBed}
      />
    </StyledSection>
  );
};
