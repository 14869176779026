import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';

export const BackgroundImage = ({ thumbnail, opacity, backgroundColor }) => (
  <>
    <Img
      style={{ position: 'absolute', height: '100%', width: '100%' }}
      objectFit="contain"
      fluid={thumbnail.childImageSharp.fluid}
    />
    <BackgroundImageOverlay
      backgroundColor={backgroundColor}
      opacity={opacity}
    />
  </>
);

const BackgroundImageOverlay = styled.div.attrs(
  ({ opacity, backgroundColor }) => ({
    opacity,
    backgroundColor,
  })
)`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({ backgroundColor }) => backgroundColor};
  opacity: ${({ opacity }) => opacity};
  position: absolute;
`;
