import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { MdMap } from 'react-icons/all';

import { StyledSection } from '../styles';
import { TypicalSection } from '../TypicalSection/typical.component';
import { Routes } from '../routes';

export const ActivitesSection = () => {
  const data = useStaticQuery(graphql`
    query {
      first: file(
        relativePath: {
          eq: "place_stanislas_nancy_proche_chateau_de_morey.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <StyledSection charlie>
      <TypicalSection
        title="home.activities.title"
        image={data.first}
        contents={['home.activities.description']}
        buttonText="home.activities.cta"
        buttonPath={Routes.HOBBY}
        buttonIcon={MdMap}
        imageAlt="home.activities.imageAlt"
      />
    </StyledSection>
  );
};
