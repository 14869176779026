import CommonEn from '../translations/common.en.json';
import HomeEn from '../translations/home.en.json';
import CommonFr from '../translations/common.fr.json';
import HomeFr from '../translations/home.fr.json';

const resources = {
  en: {
    translation: {
      common: CommonEn,
      home: HomeEn,
    },
  },
  fr: {
    translation: {
      common: CommonFr,
      home: HomeFr,
    },
  },
};

export default resources;
