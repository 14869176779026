import React, { Component } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import resources from './ressources';

export const defaultLocale = 'fr';

export const options = {
  fallbackLng: defaultLocale,
  resources,

  debug: false,

  interpolation: {
    escapeValue: false, // not needed for react!!
  },

  react: {
    wait: false,
  },

  initImmediate: false, // Important for SSR to work
};

const setupI18next = () => {
  i18n.init(options);
  return i18n;
};

const withI18next = Comp => {
  class I18nHOC extends Component {
    constructor(props) {
      super(props);
      this.i18n = setupI18next();
      this.activateLng(this.props.pageContext.langKey);
    }

    componentDidUpdate(prevProps) {
      if (this.props.pageContext.langKey !== prevProps.pageContext.langKey) {
        this.activateLng(this.props.pageContext.langKey);
      }
    }

    activateLng(locale) {
      this.i18n.changeLanguage(locale);
    }

    render() {
      return (
        <I18nextProvider i18n={this.i18n}>
          <Comp {...this.props} />
        </I18nextProvider>
      );
    }
  }

  return I18nHOC;
};

export default withI18next;
