/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import {
  BedAndBreakfast,
  Organization,
  TouristAttraction,
  Website,
  createArticle,
} from './seo.informations';

const LOCALES = {
  fr: 'fr_FR',
  en: 'en_US',
  de: 'de_DE',
};

export const TYPES = {
  home: 'HOME',
  article: 'ARTICLE',
};

const getArticleType = type => {
  switch (type) {
    case TYPES.home:
      return 'website';
    case TYPES.article:
      return 'article';
    default:
      return 'website';
  }
};

function SEO({
  description,
  lang,
  meta,
  title,
  location,
  type,
  date,
  imageUrl,
  imageWidth,
  imageHeight,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            ogImage
            ogImageWidth
            ogImageHeight
            googleSiteVerification
            social {
              facebookPageUrl
              facebookAppId
              twitter
            }
          }
        }
      }
    `
  );

  const generatedTitle = `${title} - ${site.siteMetadata.title}`;
  const image = {
    url: `${site.siteMetadata.siteUrl}${imageUrl || site.siteMetadata.ogImage}`,
    width: imageWidth || site.siteMetadata.ogImageWidth,
    height: imageHeight || site.siteMetadata.ogImageHeight,
  };
  const metaDescription = description || site.siteMetadata.description;

  const articleMeta = [
    {
      property: 'article:author',
      content: site.siteMetadata.social.facebookPageUrl,
    },
    {
      property: 'article:publisher',
      content: site.siteMetadata.social.facebookPageUrl,
    },
  ];

  return (
    <Helmet
      defer={false}
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s - ${site.siteMetadata.title}`}
      meta={[
        ...(type === TYPES.article ? articleMeta : []),
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `author`,
          content: site.siteMetadata.author,
        },
        {
          name: `robots`,
          content: 'index,follow',
        },
        {
          property: `og:locale`,
          content: LOCALES[lang],
        },
        {
          property: `og:title`,
          content: generatedTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: getArticleType(type),
        },
        {
          property: `og:url`,
          content: location.href,
        },
        {
          property: `og:publisher`,
          content: site.siteMetadata.author,
        },
        {
          property: `og:article:author`,
          content: site.siteMetadata.author,
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:image`,
          content: image.url,
        },
        {
          property: `og:image:width`,
          content: image.width,
        },
        {
          property: `og:image:height`,
          content: image.height,
        },
        {
          property: `fb:pages`,
          content: site.siteMetadata.social.facebookAppId,
        },
        {
          property: `fb:app_id`,
          content: site.siteMetadata.social.facebookAppId,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.social.twitter,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.social.twitter,
        },
        {
          name: `twitter:title`,
          content: generatedTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: image.url,
        },
        {
          property: `google-site-verification`,
          content: site.siteMetadata.googleSiteVerification,
        },
        {
          property: `mobile-web-app-capable`,
          content: 'yes',
        },
      ].concat(meta)}
    >
      <link rel="image_src" href={image.url} />
      {type === TYPES.article && (
        <script type="application/ld+json" data-testid="schema-newsarticle">
          {JSON.stringify(
            createArticle({
              title: generatedTitle,
              description: metaDescription,
              url: location.href,
              image,
              date,
            })
          )}
        </script>
      )}
      {type === TYPES.home && (
        <script type="application/ld+json" data-testid="schema-website">
          {JSON.stringify(Website)}
        </script>
      )}
      {type === TYPES.home && (
        <script type="application/ld+json" data-testid="schema-organization">
          {JSON.stringify(Organization)}
        </script>
      )}
      {type === TYPES.home && (
        <script type="application/ld+json" data-testid="schema-bnb">
          {JSON.stringify(BedAndBreakfast)}
        </script>
      )}
      {type === TYPES.home && (
        <script type="application/ld+json" data-testid="schema-tourist">
          {JSON.stringify(TouristAttraction)}
        </script>
      )}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
