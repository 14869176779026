import React from 'react';
import { Stack, Heading, Text, ButtonGroup, Button } from '@chakra-ui/core';
import { MEDIA_TABLET } from 'react-komponents';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Link from 'gatsby-link';
import { useTranslation } from 'react-i18next';

export const TypicalSection = ({
  title,
  image,
  imageAlt,
  description,
  contents,
  buttonText,
  buttonPath,
  buttonIcon,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <MainTitle>
        <Heading p={4} as="h2" fontSize="2xl" mt={4}>
          {t(title)}
        </Heading>
        <Text p={4} fontSize="lg">
          {t(description)}
        </Text>
      </MainTitle>
      <Container>
        <SeparatorText>
          <Stack>
            {contents.map(text => (
              <Text key={text} fontSize="lg" lineHeight="1.5">
                {t(text)}
              </Text>
            ))}
            <ButtonGroup spacing={4}>
              <Link to={buttonPath}>
                <Button
                  leftIcon={buttonIcon}
                  variantColor="yellow"
                  variant="solid"
                >
                  {t(buttonText)}
                </Button>
              </Link>
            </ButtonGroup>
          </Stack>
        </SeparatorText>
        <Separator>
          <Img
            title={t(imageAlt)}
            alt={t(imageAlt)}
            imgStyle={{ objectFit: 'contain' }}
            style={{ height: '100%', width: '100%' }}
            objectFit="contain"
            fluid={image.childImageSharp.fluid}
          />
        </Separator>
      </Container>
    </>
  );
};

const SeparatorText = styled.div`
  margin: ${({ theme }) => theme.spacing.large};
  display: flex;
  flex: 1;
`;

const Separator = styled.div`
  margin: ${({ theme }) => theme.spacing.large};
  display: flex;
  flex: 1;
`;

const MainTitle = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  @media (max-width: ${MEDIA_TABLET}px) {
    flex-direction: column;
    max-height: unset;
  }
`;
