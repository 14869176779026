const name = 'Château de Morey';
const address = {
  '@type': 'PostalAddress',
  streetAddress: 'Château de Morey',
  addressLocality: 'Morey',
  addressRegion: 'Lorraine',
  postalCode: '54610',
  addressCountry: 'FR',
};

const geo = {
  '@type': 'GeoCoordinates',
  latitude: 48.826438,
  longitude: 6.161825,
};

const logo = {
  '@type': 'ImageObject',
  url: 'https://chateaudemorey.fr/logo.png',
  height: '225',
  width: '500',
};

const availableLanguage = [
  {
    '@type': 'Language',
    name: 'German',
  },
  {
    '@type': 'Language',
    name: 'English',
  },
  {
    '@type': 'Language',
    name: 'French',
  },
];

export const Website = {
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  url: 'https://chateaudemorey.fr/',
  name,
  logo,
};

export const Organization = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  url: 'https://chateaudemorey.fr/',
  sameAs: [
    'https://www.facebook.com/pages/CHATEAU-DE-MOREY/251390371049',
    'https://twitter.com/CHATEAUDEMOREY',
  ],
  name,
  logo,
};

export const TouristAttraction = {
  '@context': 'http://schema.org',
  '@type': 'TouristAttraction',
  url: 'https://chateaudemorey.fr/',
  sameAs: [
    'https://www.facebook.com/pages/CHATEAU-DE-MOREY/251390371049',
    'https://twitter.com/CHATEAUDEMOREY',
  ],
  name,
  logo,
  geo,
  availableLanguage,
  address,
};

export const BedAndBreakfast = {
  '@context': 'https://schema.org',
  '@type': ['BedAndBreakfast'],
  image: ['https://chateaudemorey.fr/chateau_de_morey.jpg'],
  logo: 'https://chateaudemorey.fr/logo.png',
  sameAs: [
    'https://www.facebook.com/pages/CHATEAU-DE-MOREY/251390371049',
    'https://twitter.com/CHATEAUDEMOREY',
  ],
  '@id': 'https://chateaudemorey.fr/',
  name,
  availableLanguage,
  address,
  geo,
  checkinTime: '17:00:00',
  checkoutTime: '11:00:00',
  numberOfRooms: 5,
  petsAllowed: false,
  isAccessibleForFree: false,
  currenciesAccepted: 'EUR',
  paymentAccepted: 'Cash, Credit Card',
  url: 'https://chateaudemorey.fr/',
  telephone: '+33383315098',
  servesCuisine: 'French',
  legalName: name,
  vatId: 'FR8543474322200014',
  acceptsReservations: 'True',
};

export const createArticle = ({ title, description, url, date, image }) => ({
  '@context': 'https://schema.org',
  '@type': 'NewsArticle',
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': url,
  },
  headline: title,
  image: {
    '@type': 'ImageObject',
    url: image.url,
    height: image.height,
    width: image.width,
  },
  datePublished: date,
  dateModified: date,
  author: [{ '@type': 'Organization', name }],
  publisher: {
    '@type': 'Organization',
    name,
    logo,
  },
  description,
});
