import React, { useRef } from 'react';
import { NavigationBar } from 'react-komponents';
import { useTranslation } from 'react-i18next';
import { Logo, LogoSVG } from '../Logo/logo.component';
import {
  Drawer,
  Button,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  DrawerFooter,
  Link,
  Box,
  Flex,
  Icon,
  useDisclosure,
} from '@chakra-ui/core';
import GatsbyLink from 'gatsby-link';
import { FaBars } from 'react-icons/all';

const NavigationItems = [
  { internal: '/', name: 'common.nav.home' },
  {
    external:
      'https://reservation.elloha.com/?idPublication=c759a798-3b42-4faf-9892-79f334cc77e9&idoi=6b9e9837-2832-4a77-b9f5-6c2332f65b1a',
    name: 'common.nav.book',
  },
  { internal: '/chambres-dhotes', name: 'common.nav.bnb' },
  { internal: '/location-salles-nancy-metz', name: 'common.nav.spaceRental' },
  { internal: '/contact', name: 'common.nav.contact' },
];

function DrawerMobile() {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  return (
    <>
      <Button as={FaBars} ref={btnRef} color="teal" onClick={onOpen} />
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Box width="100%">
              <LogoSVG />
            </Box>
          </DrawerHeader>

          <DrawerBody>
            {NavigationItems.map(({ internal, external, name }) => {
              if (!internal) {
                return (
                  <Box>
                    <Link href={external} isExternal>
                      {t(name)}
                    </Link>
                  </Box>
                );
              }
              return (
                <Box>
                  <Link as={GatsbyLink} to={internal}>
                    {t(name)}
                  </Link>
                </Box>
              );
            })}
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              {t('common.nav.cancel')}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export const Navigation = ({ location, dynamic }) => {
  const { t } = useTranslation();
  return (
    <NavigationBar dynamic={dynamic}>
      {({ scrolled }) => (
        <>
          {/* Mobile */}
          <NavigationBar.Section style={{ flex: 1 }} hideOnDesktop>
            <Flex flex="4">
              <Flex flex="1" />
              <Flex
                flex="2"
                width="100%"
                height="100%"
                align="center"
                justify="center"
              >
                <Logo scrolled={dynamic && scrolled} />
              </Flex>
              <Flex flex="1" align="center" justify="flex-end">
                <DrawerMobile />
              </Flex>
            </Flex>
          </NavigationBar.Section>
          {/* Desktop */}
          <NavigationBar.Section hideOnMobile>
            <NavigationBar.List justifyContent="flex-start">
              <Logo scrolled={dynamic && scrolled} />
            </NavigationBar.List>
          </NavigationBar.Section>
          <NavigationBar.Section hideOnMobile>
            <NavigationBar.List justifyContent="flex-end">
              {NavigationItems.map(({ internal, external, name }) => {
                if (!internal) {
                  return (
                    <NavigationBar.ExternalLink href={external}>
                      {t(name)}
                    </NavigationBar.ExternalLink>
                  );
                }
                return (
                  <NavigationBar.InternalLink link={internal}>
                    {t(name)}
                  </NavigationBar.InternalLink>
                );
              })}
            </NavigationBar.List>
          </NavigationBar.Section>
        </>
      )}
    </NavigationBar>
  );
};
