import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { TitleTypingEffect } from 'react-komponents';
import { useTranslation } from 'react-i18next';
import { BackgroundImage } from '../BackgroundImage/backgroundImage.component';

export const MainBackground = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(
        relativePath: { eq: "chateau_de_morey_nancy.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <SectionBackground>
      <BackgroundImage
        thumbnail={data.backgroundImage}
        opacity={0.6}
        backgroundColor="black"
      />
      <Container>
        <StyledTitleTypingEffect
          text={t('common.hero.main')}
          delay={300}
          pause={5000}
        />
      </Container>
    </SectionBackground>
  );
};

const StyledTitleTypingEffect = styled(TitleTypingEffect)`
  margin: ${({ theme }) => theme.spacing.large};
  align-text: center;
`;

const SectionBackground = styled.section`
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
`;

const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  width: 100%;
`;
