import styled from 'styled-components';
import { Section } from 'react-komponents';

export const StyledSection = styled(Section)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
