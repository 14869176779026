import React from 'react';
import { MEDIA_TABLET } from 'react-komponents';
import { Heading, Text, ButtonGroup, Button } from '@chakra-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Link from 'gatsby-link';
import { useTranslation } from 'react-i18next';
import { StyledSection } from '../styles';
import { Routes } from '../routes';

const Item = ({ title, image, description, buttonText, buttonPath }) => (
  <>
    <Heading p={8} fontSize="2xl" mt={4}>
      {title}
    </Heading>
    <ImageContainer>
      <StyledImage
        imgStyle={{ objectFit: 'cover' }}
        objectFit="cover"
        fluid={image.childImageSharp.fluid}
      />
    </ImageContainer>
    <Text p={8} fontSize="l">
      {description}
    </Text>
    <Link to={buttonPath}>
      <ButtonGroup spacing={4}>
        <Button variantColor="yellow" variant="solid">
          {buttonText}
        </Button>
      </ButtonGroup>
    </Link>
  </>
);

export const MainSection = () => {
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      first: file(
        relativePath: { eq: "preview_chambres_proche_nancy_metz.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      second: file(
        relativePath: {
          eq: "place_stanislas_nancy_proche_chateau_de_morey.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      third: file(
        relativePath: { eq: "preview_location_de_salles_proche_nancy_metz.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <StyledSection>
      <Container>
        <Separator>
          <Item
            title={t('home.preview.bnb.title')}
            description={t('home.preview.bnb.description')}
            buttonText={t('home.preview.bnb.cta')}
            image={data.first}
            buttonPath={Routes.BNB}
          />
        </Separator>
        <Separator>
          <Item
            title={t('home.preview.hobby.title')}
            description={t('home.preview.hobby.description')}
            buttonText={t('home.preview.hobby.cta')}
            image={data.second}
            buttonPath={Routes.HOBBY}
          />
        </Separator>
        <Separator>
          <Item
            title={t('home.preview.space.title')}
            description={t('home.preview.space.description')}
            buttonText={t('home.preview.space.cta')}
            image={data.third}
            buttonPath={Routes.SPACE_RENTAL}
          />
        </Separator>
      </Container>
    </StyledSection>
  );
};

const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled(Img)`
  height: 130px;
  width: 100%;
`;

const Separator = styled.div`
  margin: ${({ theme }) => theme.spacing.large};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  @media (max-width: ${MEDIA_TABLET}px) {
    flex-direction: column;
  }
`;
