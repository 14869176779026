import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { MdBook } from 'react-icons/all';

import { StyledSection } from '../styles';
import { TypicalSection } from '../TypicalSection/typical.component';
import { Routes } from '../routes';

export const SpaceSection = () => {
  const data = useStaticQuery(graphql`
    query {
      first: file(
        relativePath: { eq: "location_de_salles_entre_nancy_et_metz.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <StyledSection alpha>
      <TypicalSection
        title="home.space.title"
        image={data.first}
        description="home.space.description"
        contents={['home.space.p1', 'home.space.p2']}
        buttonText="home.space.cta"
        buttonPath={Routes.SPACE_RENTAL}
        buttonIcon={MdBook}
        imageAlt="home.space.imageAlt"
      />
    </StyledSection>
  );
};
