import React from 'react';
import GatsbyLink from 'gatsby-link';
import { Link } from '@chakra-ui/core';
import styled from 'styled-components';
import { LinkContext, LinkStyle } from 'react-komponents';

const LinkStyled = styled(Link)`
  white-space: nowrap;
  text-decoration: inherit;
  font-family: inherit;
  ${({ active }) => active && LinkStyle}
  color: inherit;
`;

const CustomLink = ({ children, ...otherProps }) => {
  return (
    <LinkStyled as={GatsbyLink} {...otherProps}>
      {children}
    </LinkStyled>
  );
};

const Providers = ({ children }) => (
  <LinkContext.Provider value={CustomLink}>{children}</LinkContext.Provider>
);

export default Providers;
