import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { MEDIA_TABLET } from 'react-komponents';
import { Heading, Text } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';

import { StyledSection } from '../styles';
import { ArticlePreview } from '../ArticlePreview/articlePreview.component';

const options = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};
export const ArticleSection = () => {
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query lastArticles {
      articles: allMdx(
        limit: 4
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { template: { eq: "post" } } }
      ) {
        edges {
          node {
            fields {
              slug
              path
            }
            excerpt
            timeToRead
            frontmatter {
              title
              categories
              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              date
              template
            }
          }
        }
      }
    }
  `);

  return (
    <StyledSection beta>
      <Heading p={4} as="h2" fontSize="2xl" mt={4}>
        {t('home.blog.title')}
      </Heading>
      <Text p={4} fontSize="lg">
        {t('home.blog.description')}
      </Text>
      <Container>
        {data.articles.edges.map(({ node }) => (
          <ItemContainer key={node.frontmatter.slug}>
            <ArticlePreview
              title={node.frontmatter.title}
              date={new Date(node.frontmatter.date).toLocaleDateString(
                'fr',
                options
              )}
              description={node.excerpt}
              path={node.fields.path}
              thumbnail={node.frontmatter.thumbnail}
            />
          </ItemContainer>
        ))}
      </Container>
    </StyledSection>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  @media (max-width: ${MEDIA_TABLET}px) {
    flex-direction: column;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-grow: 1;
  height: 500px;
`;
