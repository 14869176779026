import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Icon,
  Text as TextComponent,
  Stack,
  Link,
} from '@chakra-ui/core';
import { MdPhone, MdEmail, MdLocationOn } from 'react-icons/md';
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
  FaFacebookMessenger,
} from 'react-icons/fa';
import {
  Footer as FooterComponent,
  Banner,
  Text,
  MEDIA_TABLET,
  Section,
} from 'react-komponents';
import PKG from '../../../package.json';

import { Logo } from '../Logo/logo.component';
import { LastArticlesSection } from '../Section/lastArticles.component';

const ContactInformation = () => {
  const { t } = useTranslation();

  return (
    <Stack mb={8}>
      <ContactContainer>
        <Flex
          flex="1"
          m={4}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Icon as={MdPhone} size="32px" color="black" />
          <Link isExternal href={t('common.contact.phoneAction')}>
            <TextComponent textAlign="center" fontWeight="bold">
              {t('common.contact.phone')}
            </TextComponent>
          </Link>
          <TextComponent textAlign="center" fontSize="sm">
            {t('common.contact.availability')}
          </TextComponent>
        </Flex>
        <Flex
          flex="1"
          m={4}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Icon as={MdEmail} size="32px" color="black" />
          <Link isExternal href={t('common.contact.mailAction')}>
            <TextComponent textAlign="center" fontWeight="bold">
              {t('common.contact.mail')}
            </TextComponent>
          </Link>
        </Flex>
        <Flex
          flex="1"
          m={4}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Icon as={MdLocationOn} size="32px" color="black" />
          <Link isExternal href={t('common.contact.locationAction')}>
            <TextComponent textAlign="center" fontWeight="bold">
              {t('common.contact.locationName')}
            </TextComponent>
          </Link>
          <TextComponent textAlign="center" fontSize="sm">
            {t('common.contact.locationDetail')}
          </TextComponent>
        </Flex>
      </ContactContainer>
      <IconContainer>
        <Link m={4} isExternal href={t('common.contact.facebook')}>
          <FaFacebook size="32px" color="black" />
        </Link>
        <Link m={4} isExternal href={t('common.contact.messenger')}>
          <FaFacebookMessenger size="32px" color="black" />
        </Link>
        <Link m={4} isExternal href={t('common.contact.instagram')}>
          <FaInstagram size="32px" color="black" />
        </Link>
        <Link m={4} isExternal href={t('common.contact.twitter')}>
          <FaTwitter size="32px" color="black" />
        </Link>
        <Link m={4} isExternal href={t('common.contact.linkedin')}>
          <FaLinkedinIn size="32px" color="black" />
        </Link>
      </IconContainer>
    </Stack>
  );
};
const ContactContainer = styled.div`
  display: flex;
  flex: 1;
  flex-grow: 1;
  margin-bottom: 8px;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  @media (max-width: ${MEDIA_TABLET}px) {
    flex-direction: column;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex: 1;
  flex-grow: 1;
  margin-bottom: 8px;
  flex-wrap: wrap;
  justify-content: center;
`;

const StyledLogo = styled(Logo)`
  @media (max-width: ${MEDIA_TABLET}px) {
    display: block;
    margin: auto;
  }
`;

export const Footer = ({ location }) => {
  const { t } = useTranslation();

  return (
    <TopContainer>
      {location.pathname !== '/' && <LastArticlesSection />}
      <FooterComponent
        top={
          <Section noPadding alpha>
            <ContactInformation />
            <StyledLogo />
          </Section>
        }
      >
        <FooterComponent.Section key="first">
          <FooterComponent.InternalLink link="/">
            {t('common.nav.home')}
          </FooterComponent.InternalLink>
          <FooterComponent.InternalLink link="/blog">
            {t('common.nav.blog')}
          </FooterComponent.InternalLink>
          <FooterComponent.ExternalLink href="https://reservation.elloha.com/?idPublication=c759a798-3b42-4faf-9892-79f334cc77e9&idoi=6b9e9837-2832-4a77-b9f5-6c2332f65b1a">
            {t('common.nav.book')}
          </FooterComponent.ExternalLink>
          <FooterComponent.InternalLink link="/chambres-dhotes">
            {t('common.nav.bnb')}
          </FooterComponent.InternalLink>
        </FooterComponent.Section>
        <FooterComponent.Section key="second">
          <FooterComponent.InternalLink link="/location-salles-nancy-metz">
            {t('common.nav.spaceRental')}
          </FooterComponent.InternalLink>
          <FooterComponent.InternalLink link="/le-chateau">
            {t('common.nav.castle')}
          </FooterComponent.InternalLink>
          <FooterComponent.InternalLink link="/contact">
            {t('common.nav.contact')}
          </FooterComponent.InternalLink>
          <FooterComponent.InternalLink link="/legal">
            {t('common.nav.legal')}
          </FooterComponent.InternalLink>
        </FooterComponent.Section>
        <FooterComponent.Section key="third">
          <FooterComponent.InternalLink link="/les-loisirs-nancy-metz">
            {t('common.nav.hobby')}
          </FooterComponent.InternalLink>
          <FooterComponent.InternalLink link="/cgv">
            {t('common.nav.cgv')}
          </FooterComponent.InternalLink>
          <FooterComponent.InternalLink link="/partners">
            {t('common.nav.partners')}
          </FooterComponent.InternalLink>
        </FooterComponent.Section>
      </FooterComponent>
      <StyledBanner
        left={<StyledText>Made with ❤ in Nancy, Lorraine</StyledText>}
        right={
          <StyledText>
            <FooterComponent.ExternalLink href="https://julienkarst.com">
              {`Copyright © ${new Date().getFullYear()} by Julien Karst. Version ${
                PKG.version
              }`}
            </FooterComponent.ExternalLink>
          </StyledText>
        }
      />
    </TopContainer>
  );
};
const StyledText = styled(Text)`
  margin: 8px;
`;

const StyledBanner = styled(Banner)`
  background-color: black;
`;

const TopContainer = styled.div``;
