import React from 'react';
import {
  Button,
  ButtonGroup,
  Heading,
  Link,
  Text,
  Flex,
} from '@chakra-ui/core';
import GatsbyLink from 'gatsby-link';
import { makeWidget } from '@typeform/embed';

const LinkTypes = {
  booking:
    'https://reservation.elloha.com/?idPublication=c759a798-3b42-4faf-9892-79f334cc77e9&idoi=6b9e9837-2832-4a77-b9f5-6c2332f65b1a',
};

export const MDXComponents = {
  h1: ({ children }) => (
    <Heading
      mt={4}
      mb={4}
      style={{ wordWrap: 'break-word' }}
      as="h1"
      fontSize="5xl"
    >
      {children}
    </Heading>
  ),
  h2: ({ children }) => (
    <Heading
      mt={4}
      mb={4}
      style={{ wordWrap: 'break-word' }}
      as="h2"
      fontSize="4xl"
    >
      {children}
    </Heading>
  ),
  h3: ({ children }) => (
    <Heading
      mt={4}
      mb={4}
      style={{ wordWrap: 'break-word' }}
      as="h3"
      fontSize="3xl"
    >
      {children}
    </Heading>
  ),
  h4: ({ children }) => (
    <Heading
      mt={4}
      mb={4}
      as="h4"
      style={{ wordWrap: 'break-word' }}
      fontSize="2xl"
    >
      {children}
    </Heading>
  ),
  h5: ({ children }) => (
    <Heading
      mt={4}
      mb={4}
      as="h5"
      style={{ wordWrap: 'break-word' }}
      fontSize="xl"
    >
      {children}
    </Heading>
  ),
  h6: ({ children }) => (
    <Heading
      mt={4}
      mb={4}
      as="h6"
      style={{ wordWrap: 'break-word' }}
      fontSize="lg"
    >
      {children}
    </Heading>
  ),
  p: ({ children }) => (
    <Text mt={4} mb={4} style={{ wordWrap: 'break-word' }} fontSize="md">
      {children}
    </Text>
  ),
  ButtonInternal: ({ to, children }) => {
    return (
      <Flex align="center" justify="center">
        <ButtonGroup spacing={4} mt={4} mb={4}>
          <Link as={GatsbyLink} to={to} style={{ textDecoration: 'none' }}>
            <Button variantColor="yellow" variant="solid">
              {children}
            </Button>
          </Link>
        </ButtonGroup>
      </Flex>
    );
  },
  ButtonExternal: ({ href, type, children }) => {
    return (
      <Flex align="center" justify="center">
        <ButtonGroup spacing={4} mt={4} mb={4}>
          <Link
            href={LinkTypes[type] || href}
            isExternal
            style={{ textDecoration: 'none' }}
          >
            <Button variantColor="yellow" variant="solid">
              {children}
            </Button>
          </Link>
        </ButtonGroup>
      </Flex>
    );
  },
  Form: ({ url }) => {
    const [ref, setRef] = React.useState();

    React.useEffect(() => {
      if (ref) makeWidget(ref, url, { opacity: 0 });
    }, [ref]);

    return <div ref={setRef} style={{ width: '100%', height: '500px' }} />;
  },
};
