import React from 'react';
import styled, {
  ThemeProvider as StyledThemeProvider,
} from 'styled-components';
import { GlobalStyles, lightTheme, Section } from 'react-komponents';
import { useSSR } from 'react-i18next';
import { MDXProvider } from '@mdx-js/react';

import Providers from '../providers';
import './layout.css';
import { MainBackground } from '../MainBackground/mainBackground.component';
import { Navigation } from '../Navigation/navigation.component';
import { Footer } from '../Footer/footer.component';
import resources from '../../i18n/ressources';
import withI18next from '../../i18n';
import { MDXComponents } from './mdxComponents';
import SEO, { TYPES } from '../seo';

export const Layout = ({ children, lang, location }) => {
  useSSR(resources, lang);
  return (
    <Providers>
      <StyledThemeProvider theme={lightTheme}>
        <GlobalStyles />
        <MDXProvider components={MDXComponents}>
          <BaseContainer>
            <ContentContainer>{children}</ContentContainer>
            <Footer location={location} />
          </BaseContainer>
        </MDXProvider>
      </StyledThemeProvider>
    </Providers>
  );
};

const BaseContainer = styled.main`
  width: 100%;
  height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  flex: 1;
  flex-grow: 1;
`;

export const withHomeLayout = Component => ({ location, children, lang }) => (
  <Component lang={lang} location={location}>
    <MainBackground />
    <Navigation location={location} dynamic />
    {children}
  </Component>
);

export const withPageLayout = Component => ({ location, children, lang }) => (
  <Component lang={lang} location={location}>
    <Navigation location={location} />
    {children}
  </Component>
);

export const withMdxLayout = Component => ({
  location,
  children,
  lang,
  pageContext,
  ...otherProps
}) => {
  const article = otherProps.pathContext.frontmatter;
  return (
    <Component lang={lang} location={location}>
      <SEO
        title={article.title}
        description={article.description}
        location={location}
        lang={pageContext.langKey}
        date={article.date}
        meta={[
          {
            property: 'article:published_time',
            content: article.date,
          },
          {
            property: 'og:datePublished',
            content: article.date,
          },
        ]}
        type={TYPES.article}
      />
      <Navigation location={location} />
      <Section beta>
        <SectionContainer>
          <MdxContainer>{children}</MdxContainer>
        </SectionContainer>
      </Section>
    </Component>
  );
};

const SectionContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 60px;
`;

export const MdxContainer = styled.div`
  max-width: 800px;
  width: 100%;
`;

export const PageLayout = withPageLayout(Layout);
export const HomeLayout = withHomeLayout(Layout);
export const MdxLayout = withMdxLayout(Layout);

export default withI18next(MdxLayout);
