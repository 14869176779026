import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/core';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Link from 'gatsby-link';
import { MEDIA_TABLET } from 'react-komponents';

export function ArticlePreview({ title, path, thumbnail, date, description }) {
  return (
    <Flex
      m={4}
      as={Link}
      to={path}
      flex="1"
      direction="column"
      borderWidth="1px"
      rounded="lg"
      backgroundColor="white"
    >
      {thumbnail && (
        <Img
          style={{ flexGrow: 1 }}
          objectFit="cover"
          alt={title}
          fluid={thumbnail.childImageSharp.fluid}
        />
      )}

      <Box p="6">
        <Box d="flex" alignItems="baseline">
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
          >
            {date}
          </Box>
        </Box>

        <Text mt="1" fontWeight="semibold" as="h4" lineHeight="tight">
          {title}
        </Text>

        <CustomText as="span" color="gray.600" fontSize="sm">
          {description}
        </CustomText>
      </Box>
    </Flex>
  );
}

const CustomText = styled(Text)`
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-width: 150ch;
  @media (max-width: ${MEDIA_TABLET}px) {
    max-width: 18ch;
  }
`;
